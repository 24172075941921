@use './variables' as variables;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font);
}

@font-face {
  font-family: Quicksand;
  src: url(./fonts/Quicksand/Quicksand-VariableFont_wght.ttf);
}

@font-face {
  font-family: Inter;
  src: url(./fonts/Inter/Inter-VariableFont_slnt,wght.ttf);
}

@mixin custom_spacings {
  @for $i from 12 through 0 {
    $i: $i * 8;

    html {
      --shadow-#{$i}: 0 0 #{$i}px #00000030;
      --space-#{$i}: #{$i}px !important;
    }

    .fs-#{$i} {
      font-size: var(--space-#{$i}) !important;
    }

    .m-#{$i} {
      margin: var(--space-#{$i}) !important;
    }

    .mx-#{$i} {
      margin-left: var(--space-#{$i}) !important;
      margin-right: var(--space-#{$i}) !important;
    }

    .ms-#{$i} {
      margin-left: var(--space-#{$i}) !important;
    }

    .me-#{$i} {
      margin-right: var(--space-#{$i}) !important;
    }

    .my-#{$i} {
      margin-top: var(--space-#{$i}) !important;
      margin-bottom: var(--space-#{$i}) !important;
    }

    .mt-#{$i} {
      margin-top: var(--space-#{$i}) !important;
    }

    .mb-#{$i} {
      margin-bottom: var(--space-#{$i}) !important;
    }

    .p-#{$i} {
      padding: var(--space-#{$i}) !important;
    }

    .px-#{$i} {
      padding-left: var(--space-#{$i}) !important;
      padding-right: var(--space-#{$i}) !important;
    }

    .ps-#{$i} {
      padding-left: var(--space-#{$i}) !important;
    }

    .pe-#{$i} {
      padding-right: var(--space-#{$i}) !important;
    }

    .py-#{$i} {
      padding-top: var(--space-#{$i}) !important;
      padding-bottom: var(--space-#{$i}) !important;
    }

    .pt-#{$i} {
      padding-top: var(--space-#{$i}) !important;
    }

    .pb-#{$i} {
      padding-bottom: var(--space-#{$i}) !important;
    }

    .shadow-#{$i} {
      box-shadow: var(--shadow-#{$i}) !important;
    }

    .rounded-#{$i} {
      border-radius: var(--space-#{$i}) !important;
    }
  }
}

@include custom_spacings;

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: var(--primary);
}

.mdc-list-group__subheader {
  font-family: var(--font);
}

.btn {
  border-radius: var(--borderRadius) !important;
  transition: 0.5s all ease;

  &:hover,
  &:focus {
    background-image: linear-gradient(45deg, #00000040, #00000040);
  }
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary);
  --bs-gradient: none;
  --bs-btn-bg: var(--primary);
  background-image: var(--primary-gradient);
}

.btn-outline-primary {
  --bs-btn-color: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary);
  --bs-gradient: none;
  background-color: #0000;
}

.text-primary {
  color: variables.$primary !important;
}

input:not(.form-control) {
  width: 100%;
  border: none;

  &:focus-visible {
    outline: unset !important;
    border-color: transparent !important;
  }

  &:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }
}

.form-control,
app-autocomplete input,
.mat-mdc-select {
  border-radius: calc(var(--borderRadius) * 0.5) !important;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #00000050 !important;
  height: 36px;

  &:focus-visible,
  &:focus-within {
    outline: 2px solid var(--primary) !important;
  }

  &:focus,
  &:focus-within {
    border-color: var(--primary) !important;
    box-shadow: 0 0 0 0.25rem #{variables.$primary}25 !important;
  }
}

.btn-group-lg>.btn,
.btn-lg {
  --bs-btn-font-size: 1rem;
}

.btn-case {
  border-radius: var(--borderRadius) !important;
}

.mat-expansion-panel {
  box-shadow: unset !important;
  border: 1px solid #00000020 !important;

  &:focus,
  &:focus-within {
    border-color: var(--primary) !important;
  }
}

loader {
  display: block;
  height: 100%;

  .center {
    height: 100%;
    //   align-items: center;
    //   justify-content: center;
    //   & > div {
    //     height: 100%;
    //   }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.info-body {
  min-height: 100px;
  padding: 0 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px !important;
}

.btn-clear {
  border-color: transparent;
  --bs-btn-disabled-border-color: transparent !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  --bs-btn-disabled-border-color: inherit;

  &.btn-primary {
    color: white;
    background-color: var(--primary);
  }
}

input[type='checkbox'] {
  /*Desired width*/
  /*Desired height*/
  zoom: 1.2;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}

input[type='checkbox']:checked {
  background-color: var(--primary);
  padding: 0.3px 6px;
  margin: 0;
  position: relative;

  &:before {
    margin: 0;
    content: '✓';
    color: white;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 6px);
  }
}

input[indeterminate='true'] {
  background-color: var(--primary);
  padding: 0.3px 6px;
  margin: 0;
  position: relative;

  &:before {
    margin: 0;
    content: '-';
    color: white;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 4px);
    font-weight: 900;
    font-size: 20px;
  }
}

input[type='checkbox'].form-control {
  padding: 0;
  width: 27px;
  height: 27px;
}

router-outlet+* {
  height: 100%;
  background-color: inherit;
  background-image: inherit;
}

date-input .customdate {
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.3137254902);
  height: 36px;

  display: flex;
  align-items: center;

  &:focus-visible {
    outline-color: var(--primary) !important;
  }

  &:focus {
    border-color: var(--primary) !important;
    box-shadow: 0 0 0 0.25rem #{variables.$primary} !important;
  }

  input {
    &:focus-visible {
      outline-color: unset !important;
    }

    &:focus {
      border-color: unset !important;
      box-shadow: unset !important;
    }
  }

  .mat-datepicker-toggle {
    display: block;
    --dimen: 24px;
    height: var(--dimen);

    .mdc-icon-button {
      padding: 0;
      line-height: var(--dimen);
      height: var(--dimen);
      width: var(--dimen);
    }
  }
}

app-card {
  .card-header {
    --bs-card-cap-color: #fff;
  }
}

a {
  text-decoration: none;
}

.notLink {
  color: inherit;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-active-focus-indicator-color: var(--primary);
  --mat-tab-header-active-focus-label-text-color: var(--primary);
  --mat-tab-header-active-hover-indicator-color: var(--primary);
  --mat-tab-header-active-hover-label-text-color: var(--primary);
  --mat-tab-header-active-label-text-color: var(--primary);
  --mat-tab-header-active-ripple-color: var(--primary);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-ripple-color: var(--primary);
  --mat-tab-header-pagination-icon-color: #000;
  --mdc-tab-indicator-active-indicator-color: var(--primary);
  --mdc-tab-indicator-active-indicator-color: var(--primary);
}

modal-header {
  .mdc-dialog__title {
    padding: 10px 15px !important;

    &::before {
      content: unset !important;
    }
  }
}

.modal-comp {
  .mat-mdc-dialog-content {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.table-container {
  border-radius: var(--borderRadius);

  td,
  th {
    padding: 1px 5px;
    width: auto;
    min-width: 1px;
    vertical-align: middle;
  }
}

mat-paginator {
  .mat-mdc-form-field {
    width: 120px;

    &>div {
      border: none;
    }

    .mdc-notched-outline {
      display: none;
    }
  }
}

.requiredTag {
  color: red;
}

.ql-editor {
  min-height: 100px;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--secondary);
  --bs-btn-border-color: var(--secondary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8a093d;
  --bs-btn-hover-border-color: #8a093d;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8a093d;
  --bs-btn-active-border-color: #8a093d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--secondary);
  --bs-btn-disabled-border-color: var(--secondary);
}

.ql-container.ngx-quill-view-html {
  padding: 10px;
}

.info-buttons {
  padding: 0 20px 20px 20px;
}

app-confirm-dialog {
  .card-body {
    white-space: break-spaces;
    padding: 0 10px;
  }
}

.showValidationIcon .form-control:not(input[type='checkbox'].form-control),
.showValidationIcon select.form-control:not(input[type='checkbox'].form-control) {
  padding: 5px 36px 10px 12px !important;
}

.divider {
  border: 1px solid #0000003f;
  height: 100%;
}

.text-secondary {
  color: var(--secondary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.menuModal {

  a:hover,
  a:focus {
    color: var(--primary) !important;
  }
}

.mat-mdc-select-panel {
  border-radius: var(--borderRadius) !important;
}

.mat-mdc-option.empty-option {
  height: 30px;
  min-height: 20px;
}

.mat-mdc-tab-body-content {
  padding: 10px;
}

text-case-2 {
  label {
    font-weight: 500;
  }
}

.successCell {
  padding: 4px 8px;
  background-color: rgb(0, 183, 0);
  color: #eaffea;
  border-radius: var(--border-radius);
}

.pendingCell {
  padding: 4px 8px;
  background-color: rgb(252, 205, 96);
  color: #816500;
  border-radius: var(--border-radius);
}

.inertCell {
  padding: 4px 8px;
  background-color: rgb(206, 206, 206);
  color: #464646;
  border-radius: var(--border-radius);
}

.errorCell {
  padding: 4px 8px;
  background-color: #df0000;
  color: #fff2f2;
  border-radius: var(--border-radius);
}

.break-words {
  white-space: break-spaces;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
  --mat-radio-checked-ripple-color: var(--primary);
}

button {
  height: 38px;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid #03932a45;
}

app-day-hour-min-input .row {
  --bs-gutter-x: 0.25rem;
}

app-input,
app-input-basic {
  &.text-center {

    app-input-label>div,
    .input-content {
      justify-content: center;
    }
  }
}

app-input-label {
  height: 30px;
  display: inline-block;
}

input::placeholder {
  opacity: 0.7;
}

@media print {
  .no-scrsht {
    display: none;
  }
}

.img-icon {
  --dimn: 24px;
  height: var(--dimn) !important;
  width: var(--dimn) !important;
}

.mdc-checkbox__background {
  border-radius: var(--border-radius) !important;
}

.iti__country-container {
  padding-right: 20px !important;
}

.mat-pseudo-checkbox {
  display: none;
}

.customdate-picker {
  .mat-pseudo-checkbox {
    display: none;
  }
}
.mat-mdc-tooltip-surface {
    white-space: break-spaces;
}