/* You can add global styles to this file, and also import other style files */

:root {
  --primary: #03932a;
  --nigeria: #03932a;
  --secondary: #bb0c53;
  --primary-color: #fff;
  --primary-gradient: linear-gradient(90deg, var(--primary), #00000027);
  --font: Raleway, Inter, Quicksand, Roboto, 'Helvetica Neue', sans-serif;
  --mat-toolbar-title-text-font: var(--font);
  --mdc-list-list-item-supporting-text-font: var(--font);
  --mdc-list-list-item-label-text-font: var(--font);
  --mdc-text-button-label-text-font: var(--font);
  --bs-primary: var(--primary) !important;
  --primary-rgb: 3, 147, 42 !important;
  --bs-primary-rgb: var(--primary-rgb) !important;
  --bs-link-color-rgb: var(--primary-rgb) !important;
  --bs-link-hover-color: var(--secondary);
  --bs-link-hover-color-rgb: 187, 12, 83;
  --borderRadius: 16px;
  --border-radius: var(--borderRadius);
  --mat-expansion-container-shape: var(--borderRadius);
  --mdc-dialog-container-shape: var(--borderRadius);
  --mdc-text-button-label-text-tracking: unset;
  --mat-menu-container-shape: var(--border-radius);
  --mat-stepper-header-edit-state-icon-background-color: var(--primary);
  --mat-option-selected-state-label-text-color: var(--primary);
  --mat-stepper-header-selected-state-icon-background-color: var(--secondary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary);
}

$primary: #03932a;