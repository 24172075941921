@import url(./loader.scss);
:root {
  --disabled-input-bg: #f4f4f4;
  --borderRadius: 16px;
  --mat-stepper-container-color: #ffffff00;
}
html {
  --mat-stepper-header-edit-state-icon-background-color: var(--primary);
}

@font-face {
  font-family: Raleway;
  src: url(../fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf);
  font-style: italic;
}
@font-face {
  font-family: Raleway;
  src: url(../fonts/Raleway/Raleway-VariableFont_wght.ttf);
  font-style: normal;
}

@mixin custom_spacings {
  @for $i from 12 through 0 {
    $i: $i * 8;

    html {
      --shadow-#{$i}: 0 0 #{$i}px #00000030;
      --space-#{$i}: #{$i}px !important;
    }

    .fs-#{$i} {
      font-size: var(--space-#{$i}) !important;
    }

    .m-#{$i} {
      margin: var(--space-#{$i}) !important;
    }

    .mx-#{$i} {
      margin-left: var(--space-#{$i}) !important;
      margin-right: var(--space-#{$i}) !important;
    }

    .ms-#{$i} {
      margin-left: var(--space-#{$i}) !important;
    }

    .me-#{$i} {
      margin-right: var(--space-#{$i}) !important;
    }

    .my-#{$i} {
      margin-top: var(--space-#{$i}) !important;
      margin-bottom: var(--space-#{$i}) !important;
    }

    .mt-#{$i} {
      margin-top: var(--space-#{$i}) !important;
    }

    .mb-#{$i} {
      margin-bottom: var(--space-#{$i}) !important;
    }

    .p-#{$i} {
      padding: var(--space-#{$i}) !important;
    }

    .px-#{$i} {
      padding-left: var(--space-#{$i}) !important;
      padding-right: var(--space-#{$i}) !important;
    }

    .ps-#{$i} {
      padding-left: var(--space-#{$i}) !important;
    }

    .pe-#{$i} {
      padding-right: var(--space-#{$i}) !important;
    }

    .py-#{$i} {
      padding-top: var(--space-#{$i}) !important;
      padding-bottom: var(--space-#{$i}) !important;
    }

    .pt-#{$i} {
      padding-top: var(--space-#{$i}) !important;
    }

    .pb-#{$i} {
      padding-bottom: var(--space-#{$i}) !important;
    }

    .shadow-#{$i} {
      box-shadow: var(--shadow-#{$i}) !important;
    }
    .rounded-#{$i} {
      border-radius: var(--space-#{$i}) !important;
    }
  }
}

@include custom_spacings;
router-outlet + * {
  height: 100%;
}

.btn-raised {
  transition:
    box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.btn-raised:not([disabled]):active,
.btn-raised:not([disabled]):focus,
.btn-raised:not([disabled]):hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transform: translate3d(0, -10px, 0);
}
.customdate {
  .mat-mdc-icon-button {
    --mdc-icon-button-icon-color: var(--primary);
  }

  .mdc-notched-outline {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: auto;
    padding: 0;
  }

  .mdc-text-field--outlined {
    padding-left: 0;
  }

  &.form-control:not(input[type='checkbox'].form-control) {
    padding-right: 8px;
  }
}

.customdate-picker {
  .mat-mdc-icon-button {
    color: #000;
  }

  .calendar-cont {
    width: 280px;
  }

  @media (max-width: 700px) {
    .calendar-cont {
      width: 100%;
    }
  }

  .time-cont {
    // width: 150px;
    margin: 5% 0;

    .time-options-cont {
      overflow: auto;
      position: relative;

      .mat-button-toggle-group {
        height: calc(100% - 24px);
        overflow: auto;
        display: block;
        border: none;
        width: 40px;

        .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
          line-height: 40px;
          width: 40px;
        }

        .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
          padding: 0;
        }

        .mat-button-toggle {
          display: block !important;
          border: none !important;
        }

        .mat-button-toggle-checked {
          background-color: var(--primary) !important;
          color: #fff !important;
        }
      }
    }
  }

  .mat-calendar-controls {
    margin: 0;
  }
}

.mat-datepicker-content {
  border-radius: var(--border-radius) !important;
}

.full-width.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 100%;
}

.hide-scroll-x,
.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scroll-x::-webkit-scrollbar,
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.overflow-x-auto {
  overflow-x: auto;
  white-space: nowrap;
}

.iti {
  width: 100%;
}
.iti__flag-container {
  right: 15px;
}

@keyframes animateBorderPrimary {
  0% {
    border-color: var(--primary);
  }

  50% {
    border-color: var(--primary);
  }
}
.animate-border-primary {
  animation-name: animateBorderPrimary;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  border: 1px solid #dcdcdc33;
}
.text-ellipsis {
  text-overflow: ellipsis;
  /* word-wrap: normal; */
  /* height: 20px; */
  /* width: 100%; */
  overflow: hidden;
  white-space: nowrap;
}
.pointer {
  cursor: pointer;
}

.mat-tooltip,
.mat-mdc-tooltip {
  white-space: pre;
}
.text-shadow {
  text-shadow: 0 0 10px #000000bd;
}

.table-container {
  .cellCont {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cell {
    display: inline-block;
  }
  .expandBtn {
    min-height: 1px;
    height: auto;
    width: auto;
    font-size: inherit;
    padding: 5px;
    margin-left: 5px;
  }
}

.table-https {
  .not-allowed-search {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.form-control.disabled,
.form-control:disabled,
.form-control[readonly],
.form-control input:disabled,
.form-control input[readonly] {
  background-color: var(--disabled-input-bg);
  cursor: default;
}
.mdc-tooltip__surface.mdc-tooltip__surface-animation {
  white-space: break-spaces;
}

@keyframes caution {
  0% {
    color: red;
  }
  50% {
    color: black;
  }
  100% {
    color: red;
  }
}
.caution {
  animation: caution;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  font-weight: 600;
}