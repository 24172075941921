[class^='vh-'] {
  height: auto !important;
}

.vh-100 {
  min-height: 100vh !important;
}

.vh-95 {
  min-height: 95vh !important;
}

.vh-90 {
  min-height: 90vh !important;
}

.vh-80 {
  min-height: 80vh !important;
}

.vh-70 {
  min-height: 70vh !important;
}

.vh-75 {
  min-height: 75vh !important;
}

.vh-60 {
  min-height: 60vh !important;
}

.vh-50 {
  min-height: 50vh !important;
}

.vh-40 {
  min-height: 40vh !important;
}

.vh-30 {
  min-height: 30vh !important;
}

.vh-20 {
  min-height: 20vh !important;
}

.vh-10 {
  min-height: 10vh !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
